<template>
  <div class="RedpackerBankForm-wrapper">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                @update="update">
      <col2-detail>
        <col2-block title="指标配置">
          <el-form-item label="指标名称"
                        :rules="[{ required: true, message: '请输入指标名称', trigger: 'blur' }]"
                        prop="name">
            <v-input v-model="form.name"
                     placeholder="请输入名称"
                     :width="width" />
          </el-form-item>
          <el-form-item label="指标类型"
                        :rules="[{ required: true, message: '请选择承担方', trigger: 'change' }]"
                        prop="categoryCode">
            <v-select v-model="form.categoryCode"
                      :options="categoryCodeOps"
                      :width="width"
                      @change="categoryCodeChange" />
          </el-form-item>
          <el-form-item label="统计口径"
                        :rules="[{ required: true, message: '请选统计口径', trigger: 'change' }]"
                        prop="caliberCode">
            <v-select v-model="form.caliberCode"
                      :options="caliberCodeOps"
                      :width="width"
                      @change="caliberCodeChange" />
          </el-form-item>
          <el-form-item label="指标周期"
                        :rules="[{ required: true, message: '请选指标周期', trigger: 'change' }]"
                        prop="period">
            <v-select v-model="form.period"
                      :options="periodOps"
                      :width="width" />
          </el-form-item>
          <el-form-item label="指标年份"
                        :rules="[{ required: true, message: '请选指标年份', trigger: 'change' }]"
                        prop="evaluateYear">
            <v-datepicker label=""
                          type="year"
                          v-model="form.evaluateYear"
                          placeholder="请选择年份"
                          :width="width"
                          :clearable="clearable"></v-datepicker>
          </el-form-item>
          <el-form-item label="设置指标"
                        :rules="[{ required: true, message: '请输入设置指标', trigger: 'blur' }]"
                        prop="indicator">
            <v-input v-model="form.indicator"
                     placeholder="请输入设置指标"
                     :width="width" />
          </el-form-item>
          <el-form-item label="考核对象"
                        prop="communityIds"
                        :rules="[ { type: 'array', required: true,  message: '当前选项不允许为空', trigger: 'change' }  ]">
            <chosenListPanel key="communityIds"
                             :list.sync="form.communityIds"
                             @select="toSelectCommunityIds"></chosenListPanel>
          </el-form-item>
        </col2-block>
        <multi-select title="项目列表"
                      :isShow.sync="selectCommunityShow"
                      :searchUrl="getCommunityListURL"
                      :headers="communitySelectTableHeader"
                      :searchParams="communitySearchParams"
                      :responseParams="communityResponseParams"
                      :responseKey="communityResponseKey"
                      :backFill.sync="form.communityIds"
                      :extraParams="communityExtraParams"
                      @callback="communityIdChange">
          <template #searchSlot>
            <v-input label="项目名称"
                     v-model="communitySearchParams.communityName"></v-input>
            <v-select label="区域类型"
                      v-model="communitySearchParams.searchRegionType"
                      :options="searchRegionTypeOps"
                      @change="searchRegionTypeChange"></v-select>
            <v-select2 label="所属区域"
                       ref="regionName"
                       v-model="communitySearchParams.regionId"
                       v-bind="allRegionParams"
                       :subjoin="{regionType : communitySearchParams.searchRegionType}"></v-select2>
            <v-input label="所在省"
                     v-model="communitySearchParams.province"></v-input>
            <v-input label="所在城市"
                     v-model="communitySearchParams.city"></v-input>
            <v-input label="所在区"
                     v-model="communitySearchParams.area"></v-input>
            <!-- <v-select2 label="运营项目组"
                       v-model="communitySearchParams.operateRegionId"
                       v-bind="operateRegionParams"></v-select2> -->
            <v-select label="项目阶段状态"
                      v-model="communitySearchParams.communityStage"
                      :options="communityStageOps"></v-select>
            <v-select label="项目类型"
                      v-model="communitySearchParams.communityType"
                      :options="communityTypeOps"></v-select>
            <v-select label="项目状态"
                      v-model="communitySearchParams.communityStatus"
                      :options="communityStatusOps"></v-select>
          </template>
        </multi-select>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { submitCreateFormURL, getCategoryListURL, getCommunityListURL, getOperateRegionListURL, getCommunityStageURL, getCommunityTypeURL, getCaliberListURL, queryUrl, updateUrl } from './api'
import { periodOps, communityStatusMap, searchRegionTypeOps, communityStatusOps } from './map'
import { communityParams, allRegionParams } from 'common/select2Params'
import { Col2Detail, Col2Block, MultiSelect, ChosenListPanel } from 'components/bussiness'
import { createHTMLVNode } from 'common/vdom'

export default {
  name: 'KpiManagmentForm',
  components: {
    Col2Detail,
    Col2Block,
    MultiSelect,
    ChosenListPanel
  },
  data () {
    return {
      changeByedit: true,
      clearable: false,
      id: this.$route.query.id,
      width: 182,
      communityParams,
      allRegionParams,
      categoryCodeOps: [],
      caliberCodeOps: [],
      periodOps: periodOps(2),
      selectCommunityShow: false,
      getCommunityListURL,
      searchRegionTypeOps: searchRegionTypeOps(1),
      communityStatusOps: communityStatusOps(1),
      communityStageOps: [],
      communityTypeOps: [],
      operateRegionParams: {
        searchUrl: getOperateRegionListURL,
        request: {
          text: 'regionName',
          value: 'operateRegionId'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      communityResponseParams: {
        id: 'id',
        name: 'communityName'
      },
      communityResponseKey: {
        id: 'id',
        name: 'text'
      },
      communityExtraParams: {
        ismodal: 1
      },
      communitySearchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: undefined,
        province: '',
        city: '',
        area: '',
        operateRegionId: undefined,
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0
      },
      communitySelectTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatus',
          label: '项目状态',
          formatter: (row) => {
            return createHTMLVNode(this, communityStatusMap[row.communityStatus])
          }
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      form: {
        id: undefined,
        name: '',
        categoryName: '',
        categoryCode: undefined,
        caliberName: '',
        caliberCode: undefined,
        period: undefined,
        indicator: '',
        evaluateYear: '',
        communityIds: [],
        isPercent: 0
      },
      submitConfig: {
        queryUrl: queryUrl,
        submitUrl: submitCreateFormURL,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: 'POST'
      }
    }
  },
  computed: {
  },
  mounted () {
    const { indicatorId } = this.$route.query
    if (indicatorId !== undefined) {
      this.$setBreadcrumb('编辑')
      this.pageType = 1
      this.$refs.formPanel.getData({
        indicatorId
      })
    } else {
      this.$setBreadcrumb('新增')
    }
  },
  created () {
    this.getCategory()
    this.getCommunityStage()
  },
  methods: {
    searchRegionTypeChange (val, item) {
      this.$refs.regionName.clearValue()
      this.regionIdParams.subjoin.regionType = val
    },
    getCommunityType () {
      this.$axios.get(getCommunityTypeURL, {}).then(async res => {
        if (res.status === '100') {
          let data = res.data
          let list = [
            {
              text: '全部',
              value: null
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.value,
              value: v.id
            })
          })
          this.communityTypeOps = list
        }
      })
    },
    getCommunityStage () {
      this.$axios.get(getCommunityStageURL, {}).then(async res => {
        if (res.status === '100') {
          let data = res.data
          let list = [
            {
              text: '全部',
              value: null
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.value,
              value: v.id
            })
          })
          this.communityStageOps = list
        }
      })
    },
    getCategory () {
      this.$axios.get(getCategoryListURL, {}).then(res => {
        if (res.status === 100) {
          let data = res.data || []
          let list = [
            {
              text: '请选择',
              value: undefined
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.categoryName,
              value: v.categoryCode,
              categoryId: v.categoryId
            })
          })
          this.categoryCodeOps = list
        }
      })
    },
    categoryCodeChange (data, item) {
      if (this.form.id && this.changeByedit) {
        this.changeByedit = false
      } else {
        this.form.caliberCode = undefined
        this.caliberCodeOps = [
          {
            text: '请选择',
            value: undefined
          }
        ]
      }
      this.form.categoryName = item.text
      data && this.getcaliber(item)
    },
    getcaliber (item) {
      this.$axios.get(getCaliberListURL, {
        params: {
          categoryId: item.categoryId
        }
      }).then(res => {
        if (res.status === 100) {
          let data = res.data || []
          let list = [
            {
              text: '全部',
              value: undefined
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.caliberName,
              value: v.caliberCode,
              isPercent: v.isPercent
            })
          })
          this.caliberCodeOps = list
        }
      })
    },
    communityIdChange (list, ids) {
    },
    toSelectCommunityIds () { this.selectCommunityShow = true },
    caliberCodeChange (data, item) {
      this.form.caliberName = item && item.text ? item.text : this.form.caliberName
      this.form.isPercent = item && item.isPercent ? item.isPercent : this.form.isPercent
    },
    submitBefore (data) {
      if (data.id) {
        this.submitConfig.submitUrl = updateUrl
        this.submitConfig.submitMethod = 'PUT'
      }
      let formData = {
        indicatorId: data.id,
        caliberCode: data.caliberCode,
        caliberName: data.caliberName,
        categoryCode: data.categoryCode,
        categoryName: data.categoryName,
        evaluateYear: data.evaluateYear,
        indicator: data.indicator,
        isPercent: data.isPercent,
        name: data.name,
        period: data.period
      }
      let ids = []
      data.communityIds.forEach((item) => {
        ids.push(item.id)
      })
      formData.communityIds = ids.join(',')

      return formData
    },
    update (data) {
      this.form = Object.assign({}, data)
      this.form.communityIds = data.communityList && data.communityList.length > 0 ? data.communityList.map(item => {
        return {
          text: item.communityName,
          id: item.communityId
        }
      }) : []

      this.$nextTick()
    }
  }
}
</script>
