var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RedpackerBankForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "指标配置" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "指标名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入指标名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "name",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入名称", width: _vm.width },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "指标类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择承担方",
                            trigger: "change",
                          },
                        ],
                        prop: "categoryCode",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.categoryCodeOps,
                          width: _vm.width,
                        },
                        on: { change: _vm.categoryCodeChange },
                        model: {
                          value: _vm.form.categoryCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categoryCode", $$v)
                          },
                          expression: "form.categoryCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "统计口径",
                        rules: [
                          {
                            required: true,
                            message: "请选统计口径",
                            trigger: "change",
                          },
                        ],
                        prop: "caliberCode",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.caliberCodeOps,
                          width: _vm.width,
                        },
                        on: { change: _vm.caliberCodeChange },
                        model: {
                          value: _vm.form.caliberCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "caliberCode", $$v)
                          },
                          expression: "form.caliberCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "指标周期",
                        rules: [
                          {
                            required: true,
                            message: "请选指标周期",
                            trigger: "change",
                          },
                        ],
                        prop: "period",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.periodOps, width: _vm.width },
                        model: {
                          value: _vm.form.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "period", $$v)
                          },
                          expression: "form.period",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "指标年份",
                        rules: [
                          {
                            required: true,
                            message: "请选指标年份",
                            trigger: "change",
                          },
                        ],
                        prop: "evaluateYear",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          label: "",
                          type: "year",
                          placeholder: "请选择年份",
                          width: _vm.width,
                          clearable: _vm.clearable,
                        },
                        model: {
                          value: _vm.form.evaluateYear,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "evaluateYear", $$v)
                          },
                          expression: "form.evaluateYear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设置指标",
                        rules: [
                          {
                            required: true,
                            message: "请输入设置指标",
                            trigger: "blur",
                          },
                        ],
                        prop: "indicator",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入设置指标",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.indicator,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "indicator", $$v)
                          },
                          expression: "form.indicator",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "考核对象",
                        prop: "communityIds",
                        rules: [
                          {
                            type: "array",
                            required: true,
                            message: "当前选项不允许为空",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("chosenListPanel", {
                        key: "communityIds",
                        attrs: { list: _vm.form.communityIds },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "communityIds", $event)
                          },
                          select: _vm.toSelectCommunityIds,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("multi-select", {
                attrs: {
                  title: "项目列表",
                  isShow: _vm.selectCommunityShow,
                  searchUrl: _vm.getCommunityListURL,
                  headers: _vm.communitySelectTableHeader,
                  searchParams: _vm.communitySearchParams,
                  responseParams: _vm.communityResponseParams,
                  responseKey: _vm.communityResponseKey,
                  backFill: _vm.form.communityIds,
                  extraParams: _vm.communityExtraParams,
                },
                on: {
                  "update:isShow": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:is-show": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:backFill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                  "update:back-fill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                  callback: _vm.communityIdChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchSlot",
                    fn: function () {
                      return [
                        _c("v-input", {
                          attrs: { label: "项目名称" },
                          model: {
                            value: _vm.communitySearchParams.communityName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityName",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityName",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "区域类型",
                            options: _vm.searchRegionTypeOps,
                          },
                          on: { change: _vm.searchRegionTypeChange },
                          model: {
                            value: _vm.communitySearchParams.searchRegionType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "searchRegionType",
                                $$v
                              )
                            },
                            expression:
                              "communitySearchParams.searchRegionType",
                          },
                        }),
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              ref: "regionName",
                              attrs: {
                                label: "所属区域",
                                subjoin: {
                                  regionType:
                                    _vm.communitySearchParams.searchRegionType,
                                },
                              },
                              model: {
                                value: _vm.communitySearchParams.regionId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.communitySearchParams,
                                    "regionId",
                                    $$v
                                  )
                                },
                                expression: "communitySearchParams.regionId",
                              },
                            },
                            "v-select2",
                            _vm.allRegionParams,
                            false
                          )
                        ),
                        _c("v-input", {
                          attrs: { label: "所在省" },
                          model: {
                            value: _vm.communitySearchParams.province,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "province",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.province",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在城市" },
                          model: {
                            value: _vm.communitySearchParams.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.communitySearchParams, "city", $$v)
                            },
                            expression: "communitySearchParams.city",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在区" },
                          model: {
                            value: _vm.communitySearchParams.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.communitySearchParams, "area", $$v)
                            },
                            expression: "communitySearchParams.area",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目阶段状态",
                            options: _vm.communityStageOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityStage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityStage",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityStage",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目类型",
                            options: _vm.communityTypeOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityType",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityType",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目状态",
                            options: _vm.communityStatusOps,
                          },
                          model: {
                            value: _vm.communitySearchParams.communityStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityStatus",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityStatus",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }